<template>
  <div class="form-elements">
    <div class="va-row">
      <div class="flex md12">
        <vuestic-widget>
          <div class="direita">
            <button
              v-show="!mostrar"
              class="btn btn-micro"
              @click.prevent="motrarTipo(), limparCampos()"
            > <i class="fa fa-plus"></i>
            </button>
            <button
              class="btn btn-micro"
              v-show="mostrar"
              @click.prevent="motrarTipo(), limparCampos()"
            > <i class="fa fa-minus"></i>
            </button>
          </div>
          <form
            action=""
            autocomplete="off"
          >
            <div
              class="va-row "
              v-show="mostrar"
            >
              <div class="flex md10 xs12 ">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group ">
                      <input
                        v-model="grupo.nome"
                        id="tipo"
                        required
                      />
                      <label
                        class="control-label"
                        for="tipo"
                      >Nome do Grupo
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="flex md2 xs12">
                <button
                  v-if="!editar"
                  class="btn btn-primary btn-micro"
                  @click.prevent="cadastrarGrupos()"
                >Cadastrar</button>
                <button
                  v-else
                  class="btn btn-info btn-micro"
                  @click.prevent="editarGrupo(grupo.id)"
                >Editar</button>
              </div>
            </div>
          </form>
          <div class="table-responsive">
            <div>
              <data-tables
                :actionCol="actionCol"
                :data="grupos"
              >
                <el-table-column
                  prop="nome"
                  label="Nome"
                  sortable="custom"
                />
              </data-tables>
            </div>
          </div>
          <vuestic-modal
            :show.sync="show"
            v-bind:small="true"
            v-bind:force="true"
            ref="staticModal"
            v-on:ok="deletarGrupos(excluir)"
            okText="Excluir"
            cancelText="Cancelar"
          >
            <div slot="title">Excluir {{this.excluir}}</div>
            <div>Você deseja Excluir?</div>
          </vuestic-modal>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cadastrar-relatos',
  data() {
    return {
      show: true,
      excluir: null,
      editar: false,
      grupos: [],
      grupo: {
        nome: '',
      },
      mostrar: false,
      actionCol: {
        label: 'Editar / Deletar',
        props: {
          align: 'center',
        },
        buttons: [
          {
            props: {
              circle: true,
              type: 'primary',
              icon: 'el-icon-edit',
            },
            handler: (row) => {
              this.grupo = row;
              this.mostrar = true;
              this.editar = true;
            },
          },
          {
            props: {
              type: 'danger',
              icon: 'el-icon-delete',
              circle: true,
            },
            handler: (row) => {
              this.excluir = row.id;
              this.showStaticModal();
            },
          },
        ],
      },
    };
  },
  created() {
    this.getGrupos();
  },
  methods: {
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    getGrupos() {
      this.$axios.get('/api/grupo/').then((res) => {
        this.grupos = res.data;
      });
    },
    cadastrarGrupos() {
      this.$axios.post('/api/grupo/', this.grupo).then((res) => {
        this.grupos.push(res.data);
        this.grupo.nome = '';
        this.message('success', 'Novo Grupo adicionado');
      }).catch(() => {
        this.message('error', 'Erro ao cadastrar');
      });
    },
    motrarTipo() {
      this.mostrar = !this.mostrar;
    },
    deletarGrupos(id) {
      this.$axios.delete(`/api/grupo/${id}/`).then(() => {
        this.getGrupos();
        this.message('success', 'Grupo Excluido');
      }).catch(() => {
        this.message('error', 'Erro ao Excluir Grupo');
      });
    },
    editarGrupo(id) {
      this.$axios.put(`/api/grupo/${id}/`, this.grupo).then(() => {
        this.message('success', 'Grupo Editado');
        this.grupo = { nome: '' };
        this.mostrar = false;
        this.editar = false;
      }).catch(() => {
        this.message('error', 'Erro ao Editar Grupo');
      });
    },
    showStaticModal() {
      this.$refs.staticModal.open();
    },
    limparCampos() {
      this.grupo = {
        nome: '',
      };
      this.editar = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.direita {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5%;
}
</style>
